
























































































































































































import { AuditLogFilters } from "@/interfaces/dto/AuditLogFilters";
import { BvTableField, BvTableFieldArray } from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import { toTitleCase } from "../util";

const todaysDate = new Date();
const lastWeekDate = new Date(todaysDate.getTime() - 7 * 24 * 60 * 60 * 1000);

type AuditLogField = { key: string; inDetail?: boolean } & BvTableField;

@Component
export default class AuditLog extends Vue {
  public perPage = 50;
  public currentPage = 1;
  public startDate: Date = lastWeekDate;
  public endDate: Date = todaysDate;
  public currentLog: AuditLog = null;

  public get filterModels() {
    return Object.entries(this.filters).map(([name, options]) => ({
      name,
      display: toTitleCase(name),
      options,
      selected: this.getOptionSelection(name),
    }));
  }

  public onFilterInputChange(name: string, value: string) {
    switch (name) {
      case "startDate":
        this.selectedFilters.start_date = value;
        break;
      case "endDate":
        this.selectedFilters.end_date = value;
        break;
      case "action":
        this.selectedFilters.action = value;
        break;
      case "type":
        this.selectedFilters.type = value;
        break;
      case "admin":
        this.selectedFilters.admin = value;
        break;
      case "association":
        this.selectedFilters.association = value;
        break;
      case "owner":
        this.selectedFilters.owner = value;
        break;
      case "unit":
        this.selectedFilters.unit = value;
        break;
    }
  }

  public onFocusFilterInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.value) {
      input.value = null;
    }
  }

  public onBlurFilterInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.value) {
      input.value = "Any";
    }
  }

  public async onSubmitFilter(): Promise<void> {
    await this.refreshLogs();
  }

  private getOptionSelection(name: string): string {
    switch (name) {
      case "action":
        return this.selectedFilters.action as string;
      case "type":
        return this.selectedFilters.type as string;
      case "admin":
        return this.selectedFilters.admin as string;
      case "association":
        return this.selectedFilters.association as string;
      case "owner":
        return this.selectedFilters.owner as string;
      case "unit":
        return this.selectedFilters.unit as string;
    }
  }

  private get filters(): AuditLogFilters {
    const filters = this.$store.state.auditLogFilters;
    if (!filters) return {} as AuditLogFilters;

    return filters;
  }

  private selectedFilters = {} as Record<string, string | Date>;

  public fields: AuditLogField[] = [
    { key: "detail", label: "" },
    { key: "auditLogId", thClass: "d-none", tdClass: "d-none" },
    { key: "tableId", thClass: "d-none", tdClass: "d-none" },
    { key: "ownerId", thClass: "d-none", tdClass: "d-none" },
    {
      key: "entryTimestamp",
      label: "Timestamp",
      sortable: true,
      inDetail: true,
    },
    { key: "ipAddress", label: "IP Address", sortable: true, inDetail: true },
    {
      key: "adminUsername",
      label: "Employees",
      sortable: true,
      inDetail: true,
    },
    { key: "action", sortable: true, inDetail: true },
    { key: "tableName", label: "Type", sortable: true, inDetail: true },
    {
      key: "associationName",
      label: "Association",
      sortable: true,
      inDetail: true,
    },
    { key: "ownerName", label: "Owner", sortable: true, inDetail: true },
    { key: "unitNumber", label: "Unit", sortable: true, inDetail: true },
    { key: "description", sortable: true },
  ];

  public get logDetailFields(): BvTableFieldArray {
    return this.fields.filter((f) => f.inDetail);
  }

  public get logs(): AuditLog[] {
    const logs = this.$store.state.auditLogs;
    if (!logs) return [] as AuditLog[];

    return logs;
  }

  private formatTimestamp(dateTimeString: string): string {
    const date = new Date(dateTimeString);

    return new Intl.DateTimeFormat("en", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(date);
  }

  public onClickAuditLogDetail(log: AuditLog) {
    this.currentLog = log;
  }

  public async created() {
    this.selectedFilters = {
      start_date: this.startDate,
      end_date: this.endDate,
      action: "Any",
      type: "Any",
      admin: "Any",
      association: "Any",
      owner: "Any",
      unit: "Any",
    };

    await this.refreshLogs();
  }

  private async refreshLogs(): Promise<void> {
    await Promise.all([
      this.$store.dispatch("GET_AUDITLOGFILTERS", this.selectedFilters),
      this.$store.dispatch("GET_AUDITLOGS", this.selectedFilters),
    ]);
  }

  private parseOldDataFromLog(data: string): Record<string, string> {
    const rows = data.split("|");

    return Object.fromEntries(rows.map((row) => row.split("^")));
  }
}
