







































































import Vue from "vue";
import Component from "vue-class-component";
import { RouteNavConfig, OwnerRoute } from "../../router";
import store from "../../store";
import { getRouteConfig } from "../../util";

@Component
export default class OwnerDetail extends Vue {
  public tabIndex = 0;

  public routes = OwnerRoute;

  public get ownerName() {
    return store.state.currentOwner && store.state.currentOwner.fullName;
  }

  public get ownerId() {
    return store.state.currentOwner && store.state.currentOwner.ownerId;
  }

  public get ownerAssociationId() {
    return this.$route.params.associationId;
  }

  public get childViewName(): string {
    const config = getRouteConfig(this.$router, this.$route.name);

    return (config as RouteNavConfig).title;
  }

  public routeTo(route: OwnerRoute): void {
    const path = route
      .replace(":associationId", this.$route.params.associationId)
      .replace(":ownerId", this.$route.params.ownerId);

    this.$router.push(path);
  }

  public async created() {
    const ownerId = this.$route.params.ownerId;

    store.dispatch("CLEAR_CURRENT_UNITS");
    await store.dispatch("GET_OWNER", ownerId);
  }

  public mounted() {
    this.$nextTick(() => {
      if (this.$route.path.includes("payment")) {
        this.tabIndex = 1;
      } else if (this.$route.path.includes("assessment")) {
        this.tabIndex = 2;
      }

      console.log("tabindex", this.tabIndex);
    });
  }
}
