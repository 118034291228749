



























































































































import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import store from "../../store";
import { Assessment } from "../../interfaces/models/Assessment";
import { UnitAssessmentsDto } from "../../interfaces/dto/UnitAssessmentsDto";
import { UnitInfoDto } from "../../interfaces/dto/UnitInfoDto";
import EmptyTable from "../../components/empty-table.vue";

@Component({ components: { EmptyTable } })
export default class UnitAssessments extends Vue {
  public filter = "";
  private selectedUnitValue = 0;

  public get selectedUnit(): number {
    return this.selectedUnitValue;
  }

  public set selectedUnit(value: number) {
    this.selectedUnitValue = value;
  }

  public get fields() {
    if (!this.assessments.length) return [];

    return [
      { key: "date", label: "Due Date", sortable: true },
      { key: "amount", label: "Amount Delinquent", sortable: true },
      { key: "interestRate", sortable: true },
      { key: "attorneyFee", sortable: true },
      { key: "lateFee", sortable: true },
      { key: "cost", sortable: true },
      { key: "amountType", sortable: true },
      { key: "disbursedAmount", sortable: true },
      { key: "unitId", thClass: "d-none", tdClass: "d-none" },
      { key: "assesmentId", thClass: "d-none", tdClass: "d-none" },
    ];
  }

  public get totals(): UnitAssessmentsDto[] {
    const assessments = this.assessments;
    return [
      {
        totalAmount: assessments.reduce((total, a) => total + a.amount, 0),
        totalAttorneyFees: assessments.reduce(
          (total, a) => total + a.attorneyFee,
          0
        ),
        totalLateFees: assessments.reduce((total, a) => total + a.lateFee, 0),
        totalCost: assessments.reduce((total, a) => total + a.cost, 0),
        totalDisbursed: assessments.reduce(
          (total, a) => total + a.disbursedAmount,
          0
        ),
      } as UnitAssessmentsDto,
    ];
  }

  public get units(): UnitInfoDto[] {
    if (!store.state.currentUnits) return [] as UnitInfoDto[];

    return store.state.currentUnits;
  }

  public get unitSelectOptions() {
    return this.units.map((unit) => ({
      value: unit.unitId,
      text: unit.unitNumber,
    }));
  }

  public get assessments(): Assessment[] {
    if (!this.$store.state.unitAssessments) return [];

    return this.$store.state.unitAssessments;
  }

  @Watch("selectedUnit")
  public async onSelectedUnitChange(selectedUnit: number) {
    await store.dispatch("GET_UNITS_ASSESSMENTS", selectedUnit);
  }

  public async mounted() {
    if (!this.units.length) {
      const ownerId = this.$route.params.ownerId;

      await store.dispatch("GET_OWNER_UNITS", ownerId);
    }

    const unitId = this.$route.params.unitId
      ? parseInt(this.$route.params.unitId)
      : this.units[0].unitId;

    this.selectedUnit = unitId;

    console.log("OwnerAssessments, selectedUnit:", this.selectedUnit);
  }
}
