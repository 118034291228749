import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons, ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'
import store from './store'
import { money, dateFormat } from './util'

Vue.config.productionTip = false

// Install BootstrapVue
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(ModalPlugin);
Vue.use(ToastPlugin);

Vue.filter('money', money);
Vue.filter('dateFormat', dateFormat);

(async function init() {

  try {
    await store.dispatch('GET_LOGIN_STATE');
  }
  catch { /* If we can't authenticate user, let routing handle redirect to Login */ }

  if (store.getters.isUserAssociation) {
    await store.dispatch('GET_ASSOCIATION_COLLECTIONS', store.getters.userAssociationId);
  } else {
    await store.dispatch('GET_ASSOCIATIONS')
  }

  (() => {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })()
})()
