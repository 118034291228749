








import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class EmptyTable extends Vue {
  @Prop({ type: String, required: false }) readonly rowName: string | undefined;
}
