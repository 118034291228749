import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { Component, RoutePropsFunction, _RouteConfigBase } from 'vue-router/types/router';

import Associations from "../views/Associations.vue";
import Employees from "../views/Employees.vue";
import UnitSearch from "../views/UnitSearch.vue";
import AuditLog from "../views/AuditLog.vue";
import AssociationCollections from '../views/AssociationCollections.vue'
import OwnerDetail from '../views/owner-detail/OwnerDetail.vue'
import UnitInfo from '../views/owner-detail/UnitInfo.vue'
import OwnerPayments from '../views/owner-detail/OwnerPayments.vue'
import OwnerAssessments from '../views/owner-detail/OwnerAssessments.vue'
import { canPerformOperations, ClaimType } from '@/util';

Vue.use(VueRouter)

const routes: RouteNavConfig[] = [
  {
    path: '/',
    name: 'Landing',
    redirect: (to) => {
      if (store.getters.isUserAssociation) {
        var associationId = store.getters.userAssociationId;
        return `/association/collections/${associationId}`;
      } else {
        return '/associations';
      }
    }
  },
  {
    path: '/associations',
    name: 'Associations',
    title: 'Associations',
    component: Associations,
    meta: { operations: [ClaimType.CAN_LIST_DATA], },
  },
  {
    path: '/employees',
    name: 'Employees',
    title: 'Employees',
    component: Employees,
    meta: { operations: [ClaimType.CAN_LIST_USERS], },
  },
  {
    path: '/unitsearch',
    name: 'UnitSearch',
    title: 'Unit Search',
    component: UnitSearch,
    meta: { operations: [ClaimType.CAN_LIST_DATA], },
  },
  {
    path: '/audit-log',
    name: 'AuditLog',
    title: 'Audit Log',
    component: AuditLog,
    meta: { operations: [ClaimType.CAN_LIST_DATA], },
  },
  {
    path: '/association/collections/:associationId',
    name: 'AssociationCollections',
    component: AssociationCollections,
    meta: { operations: [ClaimType.CAN_LIST_DATA, ClaimType.ASSOCIATION_CLAIM], }
  },
  {
    path: '/owner/:associationId/:ownerId',
    component: OwnerDetail,
    children: [{
      path: '',
      alias: "info",
      name: "UnitInfo",
      title: "Unit Information",
      component: UnitInfo,
    },
    {
      path: 'payments/:unitId?',
      name: "OwnerPayments",
      title: "Owner Payments",
      component: OwnerPayments,
    },
    {
      path: 'assessments/:unitId?',
      name: "OwnerAssessments",
      title: "Owner Assessments",
      component: OwnerAssessments,
    }],
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!store.state.isLoggedIn) {
    store.dispatch('LOGOUT');
    next(false);
  } else if (to.meta.operations &&
    !canPerformOperations(to.meta.operations, store.state.user.claims)
  ) {
    next(false);
  } else {
    next()
  }
})

export default router

export interface RouteNavConfig extends _RouteConfigBase {
  component?: Component
  props?: boolean | Object | RoutePropsFunction
  title?: string;
  children?: RouteNavConfig[],
  meta?: { operations: string[] },
}

export enum OwnerRoute {
  Unit = "/owner/:associationId/:ownerId/info",
  Payments = "/owner/:associationId/:ownerId/payments",
  Assessments = "/owner/:associationId/:ownerId/assessments",
}
