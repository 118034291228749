
























































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "./store";
import { canPerformOperations } from "./util";

@Component
export default class App extends Vue {
  public readonly URLS = {
    login: "/identity/account/login",
    logout: "/identity/account/logout",
  };

  public username = store.state.user.userName;

  public onLogout() {
    store.dispatch("LOGOUT");
  }

  public get shouldShowNav() {
    return !store.getters.isUserAssociation;
  }

  public get shouldShowUserNameWelcome() {
    return !store.getters.isUserAssociation;
  }

  public get routes() {
    return this.$router.options.routes.filter(
      (route) =>
        route.hasOwnProperty("title") &&
        (!route.meta.operations ||
          canPerformOperations(
            route.meta.operations,
            this.$store.state.user.claims
          ))
    );
  }

  public get errorCount() {
    return this.$store.state.errors.length;
  }

  @Watch("errorCount")
  public onErrorCountChange(newCount: number, oldCount: number) {
    if (newCount > oldCount) {
      const errorMessage = this.$store.state.errors[0];
      this.$bvToast.toast(errorMessage, {
        title: "We had a problem processing your last request",
        autoHideDelay: 10000,
      });
      this.$store.commit("REMOVE_FIRST_ERROR");
    }
  }
}
