import VueRouter, { RouteConfig } from 'vue-router';
import { ClaimDto } from './interfaces/dto/ClaimDto';
import store from './store';

const { default: axios } = require('axios');

// Set up axios
export const http = axios.create({
    baseURL: "/api/"
});

export const loginUrl = '/identity/account/login';
export const logoutUrl = '/identity/account/logout';

http.interceptors.response.use(null, function (error: any) {

    if (error.response.status === 401) {
        // Redirect to login
        window.location.assign(loginUrl);
    } else if (error.response.status.toString().startsWith("4")) {
        store.commit("ADD_ERROR", error.response.data);
    }
    return Promise.reject(error);
});

export const parseBool = (value: string): boolean => {
    if (typeof value === 'boolean') return value;

    switch (value) {
        case undefined:
        case null:
        case '':
        case 'False':
            return false;

        default:
            return true;
    }
}

export const toTitleCase = (s: string): string => {
    if (!s || typeof s != "string") return s;

    return s[0].toLocaleUpperCase() + s.substr(1);
}

/***
 * View Utils
 */

export const getRouteConfig = (router: VueRouter, name: string) =>
    findRouteByName(router.options.routes, name);

const findRouteByName = (routes: RouteConfig[], name: string): RouteConfig => {
    let config = null;

    routes.find((route) => {
        const children = route.children;

        if (children) {
            const childConfig = findRouteByName(children, name)
            if (childConfig) {
                config = childConfig;
                return true;
            }
        }

        if (route.name === name) {
            config = route;
            return true;
        }
    })

    return config;
}

/**
 * Filter Functions
 */

const USMoneyFormat = new Intl.NumberFormat("en-US", {
    style: 'currency',
    currency: 'USD',
})
export const money = (value: number | string): string => {
    if (typeof value === "string") {
        value = parseFloat(value);

        if (Number.isNaN(value)) {
            throw Error(`money Filter: The provided value (${value}) cannot be parsed.`);
        }
    }

    return USMoneyFormat.format(value);
}


const dateFormatValue = new Intl.DateTimeFormat("en", { month: "long", day: "numeric", year: "numeric" });
export const dateFormat = (value: Date | string): string => {
    if (typeof value === "string") {
        value = new Date(value);

        if (Number.isNaN(value.getTime())) {
            throw Error(`date Filter: The provided value (${value}) cannot be parsed.`);
        }
    }

    return dateFormatValue.format(value);
}

/**
 * Claims and Roles
 */
export const getRole = (claims: ClaimDto[]): Role => {
    const isAdmin = claims.some(c => hasClaim(c, ClaimType.CAN_LIST_USERS, "True")) &&
        claims.some(c => hasClaim(c, ClaimType.CAN_EDIT_USERS, "True"));
    if (isAdmin) {
        return Role.ADMIN
    }

    const isEmployee = claims.some(c => hasClaim(c, ClaimType.CAN_LIST_DATA, "True"));
    if (isEmployee) {
        return Role.EMPLOYEE;
    }

    const isAssociationUser = claims.some(c => hasClaim(c, ClaimType.ASSOCIATION_CLAIM));
    if (isAssociationUser) {
        return Role.ASSOCIATION;
    }
}

export const hasClaim = (claim: ClaimDto, type: string, value?: string) => {
    const hasClaimType = claim.claimType === type;

    if (typeof value === "undefined") {
        return hasClaimType
    }

    return hasClaimType && claim.claimValue === value;
}

export const canPerformOperations = (operations: ClaimType[], claims: ClaimDto[]) => {
    return operations.some(
        operation => claims.some(userClaim => {
            const claimValue = operation === ClaimType.ASSOCIATION_CLAIM ?
                userClaim.claimValue :
                "True";

            return hasClaim(userClaim, operation, claimValue);
        })
    )
}

export enum ClaimType {
    CAN_LIST_USERS = "CanListUsers",
    CAN_EDIT_USERS = "CanEditUsers",
    CAN_LIST_DATA = "CanListData",
    CAN_EDIT_DATA = "CanEditData",
    ASSOCIATION_CLAIM = "AssociationClaim",
}

export enum Role {
    ADMIN = "Administrator",
    EMPLOYEE = "Employee",
    ASSOCIATION = "Associtation",
}