


































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import EmptyTable from "../components/empty-table.vue";
import { UserDto } from "../interfaces/dto/UserDto";
import { getRole, Role } from "../util";

@Component({ components: { EmptyTable } })
export default class Employees extends Vue {
  public filter = "";
  public filterOn: any[] = [];

  public filteredItems: UserDto[];

  public get employees(): UserDto[] {
    const employees = this.$store.state.employees;
    if (!employees) return [] as UserDto[];

    return employees;
  }

  public fields = [
    { key: "claims", thClass: "d-none", tdClass: "d-none" },
    { key: "id", thClass: "d-none", tdClass: "d-none" },
    { key: "firstName", label: "First Name", sortable: true, input: "text" },
    { key: "lastName", label: "Last Name", sortable: true, input: "text" },
    { key: "userName", label: "Username", sortable: true, input: "text" },
    { key: "email", label: "Email", sortable: true, input: "email" },
    { key: "role", label: "Role", sortable: true },
    { key: "lastLogin", sortable: true },
    { key: "edit", label: "" },
    { key: "delete", label: "" },
  ];

  public employeeRole(user: UserDto): Role {
    return getRole(user.claims);
  }

  public formatLastLogin(dateString?: string): string {
    if (dateString && !Number.isNaN(Date.parse(dateString))) {
      const date = new Date(dateString);

      return new Intl.DateTimeFormat("en-US").format(date);
    }

    return "(Has not logged in)";
  }

  public get totalRows() {
    const rows = this.filteredItems ? this.filteredItems : this.employees;

    return rows.length + 1;
  }

  public onFiltered(filteredItems: UserDto[]) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.filteredItems = filteredItems;
  }

  public async created() {
    await this.$store.dispatch("GET_EMPLOYEES");
  }

  public formModalMode: string = null;

  public currentEmployee: UserDto = null;

  public get modalTitle() {
    return this.formModalMode === "edit"
      ? `Edit ${this.currentEmployee.userName}`
      : "Create a new employee";
  }

  public get modalFormFields() {
    return this.fields.filter((f) => !!f.input);
  }

  public wantsToUpdatePassword = false;
  public currentEmployeePassword = "";

  public currentEmployeeRoleOptions = [
    { text: "Admin", value: Role.ADMIN },
    { text: "Employee", value: Role.EMPLOYEE },
  ];
  public currentEmployeeRoleSelected = "";

  public onClickEditButton(user: UserDto) {
    this.currentEmployee = user;
    this.currentEmployeeRoleSelected = getRole(user.claims);
    this.formModalMode = "edit";
  }

  public onClickNewEmployee() {
    this.currentEmployee = {} as UserDto;
    this.formModalMode = "create";
  }

  public onFormModalOk(bvModalEvt: any) {
    // Prevent modal from closing
    bvModalEvt.preventDefault();

    this.onSubmit();
  }

  public onSubmit() {
    // if (!this.checkFormValidity()) {
    //   return;
    // }

    this.$nextTick(() => {
      this.$bvModal.hide("employees-modal-form");
    });

    const action =
      this.formModalMode === "create" ? "EMPLOYEE_CREATE" : "EMPLOYEE_UPDATE";

    this.$store.dispatch(action, {
      user: this.currentEmployee,
      password: this.currentEmployeePassword,
      role: this.currentEmployeeRoleSelected,
    });
  }

  checkFormValidity() {
    const valid = (this.$refs.form as HTMLFormElement).checkValidity();
    return valid;
  }

  public onFormModalHidden() {
    this.currentEmployee = null;
    this.formModalMode = null;
    this.currentEmployeeRoleSelected = null;
    this.currentEmployeePassword = null;
  }

  public async onClickDeleteButton(user: UserDto) {
    const confirmDelete = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to delete the account for ${user.firstName} ${user.lastName}?`
    );

    if (confirmDelete) {
      this.$store.dispatch("EMPLOYEE_DELETE", user.id);
    }
  }
}
