




















































































































import Vue from "vue";
import Component from "vue-class-component";
import EmptyTable from "../../components/empty-table.vue";
import { UnitInfoDto } from "../../interfaces/dto/UnitInfoDto";
import store from "../../store";

@Component({ components: { EmptyTable } })
export default class UnitInfo extends Vue {
  public name = "unit-info";

  public reportForUnit: number = null;

  public get units(): UnitInfoDto[] {
    if (!store.state.currentUnits) return [] as UnitInfoDto[];

    return store.state.currentUnits;
  }

  public get fields() {
    if (!this.units.length) return [];

    return [
      { key: "unitId", thClass: "d-none", tdClass: "d-none" },
      { key: "associationId", thClass: "d-none", tdClass: "d-none" },
      { key: "ownerId", thClass: "d-none", tdClass: "d-none" },
      { key: "onwerName", label: "Owner", sortable: true },
      { key: "unitNumber", label: "Unit", sortable: true },
      { key: "status", sortable: true },
      { key: "assignedToFile", sortable: true },
      { key: "amountOwed", sortable: true },
      { key: "report", label: "Report" },
    ];
  }

  public get totalRows() {
    return this.units.length + 1;
  }

  public reportDateOptions = [
    { text: "All time", value: "all" },
    { text: "Select end date", value: "select" },
  ];
  public reportDateOptionSelected = "all";

  public reportDate = "";

  public reportInlcudesRemarks = true;

  public onClickReportButton(unit: UnitInfoDto) {
    this.reportForUnit = unit.unitId;
  }

  public onViewReport(): void {
    this.openReportPage();
  }
  public onPrintReport(): void {
    this.openReportPage(true);
  }
  public onCancelModal(): void {
    this.$nextTick(() => {
      this.$bvModal.hide("unit-info-generate-report");
    });
  }

  private openReportPage(wantsPrint?: boolean): void {
    const query = new URLSearchParams();

    if (this.reportDate) {
      query.append("report-date", this.reportDate);
    }

    if (this.reportInlcudesRemarks) {
      query.append("wants-remarks", this.reportInlcudesRemarks.toString());
    }

    if (wantsPrint) {
      query.append("wants-print", true.toString());
    }

    const urlString = `/report/${this.reportForUnit}?${query.toString()}`;
    const url = new URL(urlString, window.location.origin);

    window.open(
      url.toString(),
      `Report for Unit ${this.reportForUnit}`,
      "noreferrer,noopener"
    );

    this.$nextTick(() => {
      this.$bvModal.hide("unit-info-generate-report");
    });
  }
  public onModalHidden() {
    this.reportDate = "";
    this.reportForUnit = null;
    this.reportInlcudesRemarks = false;
  }

  public async created() {
    const ownerId = this.$route.params.ownerId;

    await store.dispatch("GET_OWNER_UNITS", ownerId);
  }
}
