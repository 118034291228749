














































































































import Vue from "vue";
import Component from "vue-class-component";
import { UnitInfoDto } from "@/interfaces/dto/UnitInfoDto";

@Component
export default class UnitSearch extends Vue {
  public currentPage = 1;
  public perPage = 50;

  public filter = "";
  public filterOn: any[] = [];

  public filteredItems: UnitInfoDto[];

  public get units(): UnitInfoDto[] {
    if (!this.$store.state.allUnits) return [] as UnitInfoDto[];

    return this.$store.state.allUnits;
  }

  public fields = [
    { key: "unitId", thClass: "d-none", tdClass: "d-none" },
    { key: "unitNumber", sortable: true },
    { key: "assignedToFile", sortable: true },
    { key: "status", sortable: true },
    { key: "associationId", thClass: "d-none", tdClass: "d-none" },
    { key: "associationNumber", sortable: true },
    { key: "ownerId", thClass: "d-none", tdClass: "d-none" },
    { key: "ownerName", sortable: true },
    { key: "payments", label: "Link" },
    { key: "assessments", label: "Link" },
  ];

  public get totalRows() {
    const rows = this.filteredItems ? this.filteredItems : this.units;

    return rows.length + 1;
  }

  public onFiltered(filteredItems: UnitInfoDto[]) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.filteredItems = filteredItems;
  }

  public async created() {
    await this.$store.dispatch("GET_UNITS");
  }
}
