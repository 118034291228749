import { Assessment } from '@/interfaces/models/Assessment';
import { AssociationCollections } from '@/interfaces/dto/AssociationCollections';
import { AssociationDto } from '@/interfaces/dto/AssociationDto';
import { UnitInfoDto } from '@/interfaces/dto/UnitInfoDto';
import { UserDto } from '@/interfaces/dto/UserDto';
import { Owner } from '@/interfaces/models/Owner';
import { Payment } from '@/interfaces/models/Payment';
import { getRole, http, logoutUrl, parseBool, Role } from '@/util';
import Vue from 'vue'
import Vuex, { ActionContext } from 'vuex'
import { AuditLogFilters } from '@/interfaces/dto/AuditLogFilters';
import { AuditLog } from '@/interfaces/models/AuditLog';

Vue.use(Vuex)

const store: AppStore = {
  state: {
    errors: [],
    isLoggedIn: false,
    user: null,
    associations: [],
    currentAssociation: null,
    associationCollections: null,
    currentOwner: null,
    currentUnits: null,
    allUnits: null,
    unitPayments: null,
    unitAssessments: null,
    employees: null,
    auditLogFilters: null,
    auditLogs: null,
  },
  getters: {
    isUserAdmin(state: AppState): boolean {
      return state.user &&
        getRole(state.user.claims) === Role.ADMIN;
    },
    isUserEmployee(state: AppState): boolean {
      return state.user &&
        getRole(state.user.claims) === Role.EMPLOYEE;
    },
    isUserAssociation(state: AppState): boolean {
      return state.user &&
        getRole(state.user.claims) === Role.ASSOCIATION;
    },
    userAssociationId(state: AppState): number {
      if (!state.user || !state.user.claims) {
        return null;
      }

      return parseInt(state.user.claims
        .find(c => c.claimType === 'AssociationClaim').claimValue);
    },
    currentAssociationId(state: AppState): number {
      return state.currentAssociation.associationId;
    }
  },
  mutations: {
    ADD_ERROR(state: AppState, error: string): void {
      state.errors.push(error);
    },
    REMOVE_FIRST_ERROR(state: AppState): void {
      state.errors.shift();
    },
    UPDATE_LOGIN(state: AppState, isLoggedIn: boolean): void {
      state.isLoggedIn = isLoggedIn;
    },
    UPDATE_USER(state: AppState, user: UserDto): void {
      state.user = user;
    },
    UPDATE_ASSOCIATIONS(state: AppState, associations: AssociationDto[]) {
      state.associations = associations;
    },
    UPDATE_CURRENT_ASSOCIATION(state: AppState, currentAssociation: AssociationDto) {
      state.currentAssociation = currentAssociation;
    },
    UPDATE_CURRENT_ASSOCIATION_COLLECTIONS(state: AppState, associationCollections: AssociationCollections[]) {
      state.associationCollections = associationCollections;
    },
    UPDATE_CURRENT_OWNER(state: AppState, currentOwner: Owner) {
      state.currentOwner = currentOwner;
    },
    UPDATE_ALL_UNITS(state: AppState, allUnits: UnitInfoDto[]) {
      state.allUnits = allUnits;
    },
    UPDATE_CURRENT_OWNER_UNITS(state: AppState, currentUnits: UnitInfoDto[]) {
      state.currentUnits = currentUnits;
    },
    CLEAR_CURRENT_UNITS(state: AppState, currentUnits: UnitInfoDto[]) {
      state.currentUnits = null;
    },
    UPDATE_UNITS_PAYMENTS(state: AppState, unitPayments: Payment[]) {
      state.unitPayments = unitPayments;
    },
    UPDATE_UNITS_ASSESSMENTS(state: AppState, unitAssessments: Assessment[]) {
      state.unitAssessments = unitAssessments;
    },
    UPDATE_EMPLOYEES(state: AppState, employees: UserDto[]) {
      state.employees = employees;
    },
    ADD_NEW_EMPLOYEE(state: AppState, employee: UserDto) {
      state.employees.push(employee);
    },
    UPDATE_AUDITLOGFILTERS(state: AppState, filterList: AuditLogFilters) {
      state.auditLogFilters = filterList;
    },
    UPDATE_AUDITLOGS(state: AppState, logs: AuditLog[]) {
      state.auditLogs = logs;
    },
  },
  actions: {
    async GET_LOGIN_STATE({ commit }: ActionContext<AppState, AppState>) {
      const { data: user } = await http.get('user');

      if (user) {
        commit('UPDATE_USER', user);
        commit('UPDATE_LOGIN', true);
      }
    },
    async LOGOUT({ commit }: ActionContext<AppState, AppState>) {
      await http.post('user/logout')

      commit('UPDATE_USER', null);
      commit('UPDATE_LOGIN', false);

      window.location.assign(logoutUrl);
    },
    async GET_ASSOCIATION({ commit }: ActionContext<AppState, AppState>, associationId: number) {
      const { data: association } = await http.get(`associations/${associationId}`);

      commit('UPDATE_CURRENT_ASSOCIATION', association);
    },
    async GET_ASSOCIATION_BY_NUMBER({ commit }: ActionContext<AppState, AppState>, associationNumber: string) {
      const { data: association } = await http.get(`associations/number/${associationNumber}`);

      commit('UPDATE_CURRENT_ASSOCIATION', association);
    },
    async GET_ASSOCIATIONS({ commit }: ActionContext<AppState, AppState>) {
      const { data: associations } = await http.get('associations');

      commit('UPDATE_ASSOCIATIONS', associations);
    },
    async GET_ASSOCIATION_COLLECTIONS({ dispatch, commit, state }: ActionContext<AppState, AppState>, associationId: number) {
      if (!associationId) {
        throw new Error("GET_ASSOCIATION_COLLECTIONS expects an association Id!")
      }

      let association: AssociationDto;
      if (state.associations?.length) {
        association = state.associations.find((a) =>
          a.associationId
          // Perform loose equality because `store.dispatch` can't guarantee that associationId is `number`
          ==
          associationId);

        commit('UPDATE_CURRENT_ASSOCIATION', association);
      }

      if (!(association || state.currentAssociation?.associationId === associationId)) {
        await dispatch("GET_ASSOCIATION", associationId);

        association = state.currentAssociation;
      }

      const { data: associationCollections } = await http.get(`associations/collections/${associationId}`);

      commit('UPDATE_CURRENT_ASSOCIATION_COLLECTIONS', associationCollections);
    },
    async GET_ASSOCIATION_COLLECTIONS_BY_NUMBER({ dispatch, commit }: ActionContext<AppState, AppState>, associationNumber: string) {
      if (!associationNumber) {
        throw new Error("GET_ASSOCIATION_COLLECTIONS expects an association Id!")
      }

      const { data: association }: { data: AssociationDto } =
        await dispatch("GET_ASSOCIATION_BY_NUMBER", associationNumber);

      commit('UPDATE_CURRENT_ASSOCIATION', association);

      const { data: associationCollections } = await http.get(`associations/collections/${association.associationId}`);

      commit('UPDATE_CURRENT_ASSOCIATION_COLLECTIONS', associationCollections);
    },
    async GET_OWNER({ commit }: ActionContext<AppState, AppState>, ownerId: number) {
      const { data: owner } = await http.get(`owner/${ownerId}`);

      commit('UPDATE_CURRENT_OWNER', owner);
    },
    async CLEAR_CURRENT_UNITS({ commit }: ActionContext<AppState, AppState>) {
      commit('CLEAR_CURRENT_UNITS');
    },
    async GET_UNITS({ commit }: ActionContext<AppState, AppState>) {
      const { data: units } = await http.get(`unit`);

      commit('UPDATE_ALL_UNITS', units);
    },
    async GET_OWNER_UNITS({ commit }: ActionContext<AppState, AppState>, ownerId: number) {
      const { data: units } = await http.get(`unit/owner/${ownerId}`);

      commit('UPDATE_CURRENT_OWNER_UNITS', units);
    },
    async GET_UNITS_PAYMENTS({ commit }: ActionContext<AppState, AppState>, unitId: number) {
      const { data: payments } = await http.get(`unit/${unitId}/payments`);

      commit('UPDATE_UNITS_PAYMENTS', payments);
    },
    async GET_UNITS_ASSESSMENTS({ commit }: ActionContext<AppState, AppState>, unitId: number) {
      const { data: assessments } = await http.get(`unit/${unitId}/assessments`);

      commit('UPDATE_UNITS_ASSESSMENTS', assessments);
    },
    async GET_EMPLOYEES({ commit }: ActionContext<AppState, AppState>) {
      const { data: employees } = await http.get('employees');

      commit('UPDATE_EMPLOYEES', employees);
    },
    async EMPLOYEE_CREATE({ commit }: ActionContext<AppState, AppState>,
      { user, password, role }: { user: UserDto, password: string, role: string }) {
      const { data: employee } = await http.post('employees', { user, password, role });

      commit('ADD_NEW_EMPLOYEE', employee);
    },
    async EMPLOYEE_UPDATE({ dispatch }: ActionContext<AppState, AppState>,
      { user, password, role }: { user: UserDto, password: string, role: string }) {
      await http.put('employees', { user, password, role });

      dispatch('GET_EMPLOYEES');
    },
    async EMPLOYEE_DELETE({ dispatch }: ActionContext<AppState, AppState>, id: number) {
      await http.delete(`employees/${id}`);

      dispatch('GET_EMPLOYEES');
    },
    async GET_AUDITLOGFILTERS({ commit }: ActionContext<AppState, AppState>, selectedFilters: Record<string, string>) {
      const { data: filterList } = await http.get('auditlog/filters', { params: selectedFilters });

      commit('UPDATE_AUDITLOGFILTERS', filterList);
    },
    async GET_AUDITLOGS({ commit }: ActionContext<AppState, AppState>, selectedFilters: Record<string, string>) {
      const { data: logs } = await http.get('auditlog', { params: selectedFilters });

      commit('UPDATE_AUDITLOGS', logs);
    },
  },
  modules: {
  }
}

export default new Vuex.Store(store)

export interface AppStore {
  state: AppState;
  getters?: any;
  mutations: any;
  actions: any;
  modules: any;
}

export interface AppState {
  errors: string[];
  isLoggedIn: boolean;

  user: UserDto;
  associations: AssociationDto[],
  currentAssociation: AssociationDto,
  associationCollections: AssociationCollections[]
  currentOwner: Owner,
  currentUnits: UnitInfoDto[],
  allUnits: UnitInfoDto[],
  unitPayments: Payment[],
  unitAssessments: Assessment[],
  employees: UserDto[];
  auditLogFilters: AuditLogFilters,
  auditLogs: AuditLog[]
}
