































































































import Vue from "vue";
import Component from "vue-class-component";
import { Association } from "../interfaces/models/Association";
import store from "../store";

@Component
export default class Associations extends Vue {
  public currentPage = 1;
  public perPage = 50;

  public filter = "";
  public filterOn: any[] = [];

  public filteredItems: Association[];

  public get associations(): Association[] {
    if (!store.state.associations) return [] as Association[];

    return store.state.associations;
  }

  public fields = [
    { key: "associationId", thClass: "d-none", tdClass: "d-none" },
    { key: "units", thClass: "d-none", tdClass: "d-none" },
    { key: "name", sortable: true },
    { key: "number", sortable: true },
    { key: "interestRate", sortable: true },
    { key: "unitQuantity", label: "Number of Units", sortable: true },
    { key: "password" },
  ];

  public get totalRows() {
    const rows = this.filteredItems ? this.filteredItems : this.associations;

    return rows.length + 1;
  }

  public onFiltered(filteredItems: Association[]) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.filteredItems = filteredItems;
  }

  public async created() {
    await store.dispatch("GET_ASSOCIATIONS");
  }
}
