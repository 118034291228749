



































































































import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import store from "../../store";
import { Payment } from "../../interfaces/models/Payment";
import { UnitInfoDto } from "../../interfaces/dto/UnitInfoDto";
import EmptyTable from "../../components/empty-table.vue";

@Component({ components: { EmptyTable } })
export default class UnitPayments extends Vue {
  public filter = "";
  public filterOn: any[] = [];

  private selectedUnitValue = 0;
  public filteredItems: Payment[];

  public get selectedUnit(): number {
    return this.selectedUnitValue;
  }

  public set selectedUnit(value: number) {
    this.selectedUnitValue = value;
  }

  public get units(): UnitInfoDto[] {
    if (!store.state.currentUnits) return [] as UnitInfoDto[];

    return store.state.currentUnits;
  }

  public get unitSelectOptions() {
    return this.units.map((unit) => ({
      value: unit.unitId,
      text: unit.unitNumber,
    }));
  }

  public get payments(): Payment[] {
    if (!this.$store.state.unitPayments) return [];

    return this.$store.state.unitPayments;
  }

  public get fields() {
    if (!this.payments.length) return [];

    const excludeKeys = ["unitId", "paymentId", "date"];

    return [
      "date",
      ...Object.keys(this.payments[0]).filter((k) => !excludeKeys.includes(k)),
      { key: "unitId", thClass: "d-none", tdClass: "d-none" },
      { key: "paymentId", thClass: "d-none", tdClass: "d-none" },
    ];
  }

  public getType(type: string): string {
    if (!type) return;

    switch (type) {
      case "received":
        return "T";
      case "credit":
        return "A";
      default:
        return type.toUpperCase();
    }
  }

  @Watch("filter")
  public onFilterChange(selectedUnit: number) {
    this.filteredItems = null;
  }

  @Watch("selectedUnit")
  public async onSelectedUnitChange(selectedUnit: number) {
    if (this.selectedUnit) {
      await store.dispatch("GET_UNITS_PAYMENTS", selectedUnit);
    }
  }

  public async mounted() {
    if (!this.units.length) {
      const ownerId = this.$route.params.ownerId;

      await store.dispatch("GET_OWNER_UNITS", ownerId);
    }

    const unitId = this.$route.params.unitId
      ? parseInt(this.$route.params.unitId)
      : this.units[0].unitId;

    this.selectedUnit = unitId;

    console.log("OwnerPayments, selectedUnit:", this.selectedUnit);
  }
}
