





















































































































import Vue from "vue";
import Component from "vue-class-component";
import { Association } from "../interfaces/models/Association";
import { AssociationCollections } from "../interfaces/dto/AssociationCollections";
import store from "../store";

@Component
export default class AssociationCollection extends Vue {
  public filter = "";
  public filterOn: any[] = [];
  public filteredItems: AssociationCollection[];

  public get associationName() {
    return this.currentAssociation.name;
  }
  public get associationNumber() {
    return this.currentAssociation.number;
  }

  public get associationId() {
    return this.currentAssociation.associationId;
  }

  public get currentAssociation(): Association {
    return store.state.currentAssociation || ({} as Association);
  }

  public get collections(): AssociationCollections[] {
    if (!store.state.associationCollections)
      return [] as AssociationCollections[];

    return store.state.associationCollections;
  }

  public fields = [
    { key: "ownerId", thClass: "d-none", tdClass: "d-none" },
    { key: "unitId", thClass: "d-none", tdClass: "d-none" },
    { key: "associationNumber", thClass: "d-none", tdClass: "d-none" },
    { key: "ownerName", label: "Owner", sortable: true },
    { key: "unitNumber", sortable: true },
    { key: "status", sortable: true },
    { key: "balanceOwed", sortable: true },
    { key: "inTrustAmount", sortable: true },
  ];

  public async created() {
    await store.dispatch(
      "GET_ASSOCIATION_COLLECTIONS",
      this.$route.params.associationId
    );
  }
}
